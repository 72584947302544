require('./bootstrap');
require('slick-carousel')

import Swal from 'sweetalert2';
import flatpickr from "flatpickr";

window.deleteConfirm = function(formId, name = '')
{
    console.log(formId)
    Swal.fire({
        icon: 'warning',
        text: `Do you want to delete this ${name}?`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#e3342f',
    }).then((result) => {
        if (result.isConfirmed) {
            console.log(document.getElementById(formId))
            document.getElementById(formId).submit();
        }
    });
}

window.confirmRefund = function(formId, id)
{
    Swal.fire({
        icon: 'warning',
        text: `Are you sure you want to refund this file service with an id of ${id}?`,
        showCancelButton: true,
        confirmButtonText: 'Refund',
        confirmButtonColor: '#e3342f',
    }).then((result) => {
        if (result.isConfirmed) {
            document.getElementById(formId).submit();
        }
    });
}
